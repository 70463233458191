@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
@layer utilities {
  .tooltip {
    @apply invisible absolute;
  }
  
  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
}
